<template>
  <div>
    <v-stepper :value="step">
      <v-stepper-header v-if="!idFromWebsite">
        <v-stepper-step :complete="step > 1" step="1">Terapeuta</v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 2" step="2">Dia</v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 3" step="3">Horário</v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 4" step="4">
          Confirmação
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <step-therapist />
        </v-stepper-content>
        <v-stepper-content step="2">
          <step-date />
        </v-stepper-content>
        <v-stepper-content step="3">
          <step-time />
        </v-stepper-content>
        <v-stepper-content step="4">
          <step-confirmation :id-from-website="idFromWebsite" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
export default {
  name: "StepperNewAppointment",
  components: {
    StepTherapist: () =>
      import(
        "@/domains/appointments/patient/presentation/components/stepper/Therapist"
      ),
    StepDate: () =>
      import(
        "@/domains/appointments/patient/presentation/components/stepper/Date"
      ),
    StepTime: () =>
      import(
        "@/domains/appointments/patient/presentation/components/stepper/Time"
      ),
    StepConfirmation: () =>
      import(
        "@/domains/appointments/patient/presentation/components/stepper/Confirmation"
      ),
  },
  computed: {
    step() {
      return this.$store.state.appointments.patient.step;
    },
    idFromWebsite() {
      return this.$route.query?.appointmentId;
    },
  },
  created() {
    this.autoFillData();
  },
  methods: {
    async autoFillData() {
      if (this.idFromWebsite) {
        await this.$store.dispatch(
          "appointments/patient/fillDataFromWebsite",
          this.idFromWebsite
        );
      }
    },
  },
};
</script>

<style scoped></style>
